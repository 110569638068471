import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import { graphql } from "gatsby"

import { SEO, Box, Flex, H1, Text, Wrapper, Button } from "components"
import CommunityTemplate from "templates/community"
import HighriseTemplate from "templates/highrise"
import PostTemplate from "templates/post"
import MoveInReadyTemplate from "templates/quickPossession"

function NotFoundPage(props) {
  const [template, setTemplate] = useState("")
  const [data, setData] = useState()

  // handle unpublished preview pages
  useEffect(() => {
    const PREVIEW_DATA = window.__PRISMIC_PREVIEW_DATA__
    if (PREVIEW_DATA) {
      const keys = Object.keys(PREVIEW_DATA)
      setTemplate(PREVIEW_DATA[keys[0]].type)
      setData({ ...props.data, ...PREVIEW_DATA })
    }
  }, [])

  return (
    <>
      {template === "community" && <CommunityTemplate {...props} data={data} />}
      {template === "highrise" && <HighriseTemplate {...props} data={data} />}
      {template === "post" && <PostTemplate {...props} data={data} />}
      {template === "move_in_ready_home" && (
        <MoveInReadyTemplate {...props} data={data} />
      )}
      {!template && (
        <>
          <SEO title={{ text: "404 | Partners Development Group" }} />
          <Wrapper as="section" pt={[5, 6]}>
            <Box mb={4}>
              <H1 children={`404: Page Not Found`} mb={3} />
              <Text
                children={`This page was either deleted or never existed.`}
              />
            </Box>
            <Flex flexWrap="wrap">
              <Box mr={2} mb={2}>
                <Button
                  children={`See our communities`}
                  variant="default"
                  as={Link}
                  to="/communities/"
                />
              </Box>
              <Box>
                <Button children={`Go home`} variant="alt" as={Link} to="/" />
              </Box>
            </Flex>
          </Wrapper>
        </>
      )}
    </>
  )
}

// QUERY TO BACKFILL DATA FOR PREVIEWS
export const query = graphql`
  query FourOhFourQuery {
    showhomes: allPrismicShowhome {
      nodes {
        data {
          title {
            text
          }
          type
          address {
            text
          }
          address_coordinates {
            latitude
            longitude
          }
          address_link {
            url
          }
          hours {
            html
          }
          sales_teams_headshots {
            headshot {
              url
              alt
              fluid(maxWidth: 160) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
          sales_teams_names {
            text
          }
          sales_teams_roles
          sales_team_email
          sales_team_phone
          community {
            uid
          }
        }
      }
    }
  }
`

export default NotFoundPage
